import { ChangeEvent, FC, useEffect, useState } from "react"

import { 
  analytics,
  auth , 
  db 
} 

from "../store/firebase";

import { USERS, IPADDRESS, ipAddress, uid, time_stamp, PREMIUM, premium, block } from "../keys/firestorekeys"

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './scss/LoginPage.scss'

import { useLocation } from "react-router"

import { Card, 
  CardContent, 
  Button, 
  LinearProgress, 
  CircularProgress, 
  Checkbox, 
  DialogContentText, 
  FormControlLabel, 
  Divider,
  Typography
} from '@mui/material';

import { 
  GoogleAuthProvider,
  ConfirmationResult,
   RecaptchaVerifier, 
   signInWithPhoneNumber, 
   browserLocalPersistence, 
   setPersistence, 
   getRedirectResult,
   signInWithRedirect
} from "firebase/auth";

import { getDoc, setDoc, Timestamp } from "firebase/firestore"
import { arrayUnion, doc } from "firebase/firestore"
import { country, myUid, timer } from "../keys/localStorageKeys";
import { Helper } from "../utility/Helper";
import { useUser } from "../store";
import { ProfileHelper } from "../utility/ProfileHelper";
import { isTEST } from "../keys/functionNames";
import { logEvent } from "firebase/analytics";
import { AnalyticsNames } from "../keys/analyticNames";
import LoadingScreen from "../components/Loaders/LoadingScreen";
import { AppLogo } from "../data/images";

interface LoginPageProps {
  openAdminPage: boolean
  openSubscribePage: boolean
  openBabeSignup: boolean
  openSession: string
  openVerify: string
  chooseServices: boolean
}

const LoginPage: FC<{
  goToLink?: string | undefined
  dontRedirect?: boolean | undefined
}> = ({goToLink, dontRedirect = false}) => {
  

  const ENTER_KEY_CODE = 13

  const helper = new Helper()

  const [phone, setPhone] = useState<string>()
  const location = useLocation<LoginPageProps>()

  const openBabeSignup = location.state?.openBabeSignup ?? false
  const openAdminPage = location.state?.openAdminPage ?? false
  const openSubscribePage = location.state?.openSubscribePage ?? false
  const openSession = location.state?.openSession
  const openVerify = location.state?.openVerify
  const chooseServices = location.state?.chooseServices ?? false

  const setCurrentUser = useUser((state) => state.setCurrentUser)

  const [isLoading, setLoading] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>();
  const [msg, setMsg] = useState<string>();

  const [verifying, setVerifying] = useState<boolean>(false);

  const [send, setSend] = useState<boolean>(false);

  const [result, setResult] = useState<ConfirmationResult | undefined>(undefined);
  const [ageLimit, setAgeLimit] = useState<boolean>(true);
  const [google, setGoogle] = useState<boolean>(false)

  const [countryCode, setCountryCode] = useState<string>()


  const sendPressed = async () => {

    setMsg("")
    if(phone === null || phone === undefined){
      alert("Phone nuber is required")
      return;
    }
    
    let re = /\+/gi;
    let result = phone.replace(re, "");

    const total = "+" + result
    setPhone(total)

    const disable = isTEST
    auth.settings.appVerificationDisabledForTesting = disable

    setSend(true)

    setPersistence(auth, browserLocalPersistence)

    .then(async function() {
        
        const result = await signInWithPhoneNumber(auth, total, 
        new RecaptchaVerifier('recaptcha-container', {
          'size': 'invisible'
        }, auth))

        setResult(result);

        setTimeout(() => {
          (document.getElementById("input-code") as HTMLInputElement).focus()
        }, 400)
        
        return result;
    })
    .catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      if (window.confirm(errorCode + " " + errorMessage)){
        window.location.reload();
      }

    })
  };


  const googleSignInClick = () => {

    const provider = new GoogleAuthProvider();

    setVerifying(true)
    setGoogle(true) 

    signInWithRedirect(auth, provider)
    .catch((error) => {
      console.log(error)
      setMsg("Google sign in error")
      setVerifying(false)
      setGoogle(false)
    })

    // .then(async (result) => {

    //   const _uid = result.user.uid;
    //   await logined(_uid, null)

    //   setVerifying(false)
    //   setGoogle(false)

    // })
  }
  const verifyPressed = async () => {
    setVerifying(true)
    setMsg("")
    setSend(false)
    if(verificationCode !== undefined && result !== (null || undefined)) {

      result!.confirm(verificationCode as string).then(async status => {

        if(status.operationType !== "signIn"){
      
          alert("Add country code (Eg. 65)")
          setVerifying(false)
          return
        }

        const _uid = status.user?.uid
        const _phoneNumber = status.user?.phoneNumber

        await logined(_uid, _phoneNumber)

        setVerifying(false)
   

      }).catch(() => {
        setMsg("Wrong verification code")
        setVerifying(false)
      
      })
    }
  };

  async function logined (_uid: string | null, _phoneNumber: string | null) {
    const noCache = (new Date().getTime())

    if(_uid){

      localStorage.setItem(myUid, _uid)

      try{

        //if (!_phoneNumber){

          const _country = countryCode

          setCurrentUser({country: _country})
          if(_country) localStorage.setItem(country, _country)

        //}

        const getUserDataPromise = async () => {
          const userData = await getDoc(doc(db, USERS, _uid))

          if(userData.exists()){
  
            const profileHelper = new ProfileHelper()
            profileHelper.updateUserProfile(userData, setCurrentUser)
  
            setCurrentUser({phoneNumber: _phoneNumber, uid: _uid})
            return Promise.resolve();
  
          }else{
            return setDoc(doc(db, USERS, _uid), {
              [uid]: _uid
            }, {merge: true})
          }
        }

        const getPremiumPromise = async () => {

          const getPremium = await getDoc(doc(db, PREMIUM, _uid))

          if(getPremium.exists()){
            const _isPremium = getPremium.get(premium) as boolean ?? false
            localStorage.setItem(timer, _isPremium ? "1" : "0");

            const _block = getPremium.get(block)
            const isBlock = (_block ?  true : false)

            if(!isBlock){

              const ipCallBack = await fetch('https://api.ipify.org/?format=json')
              const json = await ipCallBack.json()
              const ip = json['ip'];
                    
              await setDoc(doc(db, IPADDRESS, _uid) , 
              { [ipAddress]: arrayUnion(ip),
                [uid]: _uid,
                [time_stamp]: Timestamp.now()
              }, {merge: true})

            }

            if(isBlock) localStorage.setItem(block, "1")
            else localStorage.removeItem(block)

          }else{
            const ipCallBack = await fetch('https://api.ipify.org/?format=json')
            const json = await ipCallBack.json()
            const ip = json['ip'];
                  
            await setDoc(doc(db, IPADDRESS, _uid) , 
            { [ipAddress]: arrayUnion(ip),
              [uid]: _uid,
              [time_stamp]: Timestamp.now()
            }, {merge: true})
          }

          return Promise.resolve();
        }


        
        // const getPremium = await getDoc(doc(db, PREMIUM, _uid))
        
        // if(getPremium.exists()){
        //   const _isPremium = getPremium.get(premium) as boolean ?? false
        //   localStorage.setItem(timer, _isPremium ? "1" : "0");
        // }
      
        // const storeIPAddress = async () => {

        //   const ipCallBack = await fetch('https://api.ipify.org/?format=json')
        //   const json = await ipCallBack.json()
        //   const ip = json['ip'];

  
        //   return setDoc(doc(db, IPADDRESS, _uid) , 
        //   { [ipAddress]: arrayUnion(ip),
        //     [uid]: _uid,
        //     [time_stamp]: Timestamp.now()
        //   }, {merge: true})
        // }

        await Promise.all(
          [ getUserDataPromise(), getPremiumPromise() ]
        )


      }catch(error){
        console.log(error)
      }
      
      if(openBabeSignup){
        window.location.href = `/page/Admin?uid=${_uid}&babe=true&no=${noCache}`
      }else if(openAdminPage){
        window.location.href = `/page/Admin?uid=${_uid}&no=${noCache}`
      }else if (openSubscribePage){

        // to make sure that user will get their customer id
        setTimeout(() => {
          window.location.href = `/Subscribe?uid=${_uid}&no=${noCache}`
        }, 1000)

      }else if (openSession){
        window.location.href = `/Rent?session=${openSession}&apply=true&no=${noCache}`

      }else if(openVerify){
        window.location.href = `/${openVerify}`
        
      }else if(chooseServices){
        window.location.href = `/page/setuplocation`
      }else if(goToLink){
        window.location.href = goToLink
      }else if(dontRedirect){
        
      }
      else{
        window.location.href = `/page/Admin?uid=${_uid}&babe=true&no=${noCache}`
      }
    }else{
      window.location.href = `/page/Admin?uid=${_uid}&babe=true&no=${noCache}`
    }
  }

  const onChangeHandle = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setAgeLimit(checked)
  } 

  useEffect(() => {

    setLoading(true)

    getRedirectResult(auth)
    .then(async (result) => {

      if(!result) {
        setLoading(false)
        return
      }

      // This gives you a Google Access Token. You can use it to access Google APIs.
      const credential = GoogleAuthProvider.credentialFromResult(result)
      if(!credential) {
        setLoading(false)
        return
      }

      const _uid = result.user.uid;
      if(_uid){
        setVerifying(true)
        setGoogle(true)
        await logined(_uid, null)

        setVerifying(false)
        setGoogle(false)
      }
      
      setTimeout(() => {
        setLoading(false)
      }, 800)
 
      // IdP data available using getAdditionalUserInfo(result)
      // ...
    }).catch((error) => {
      console.log(error)
      if(error.code === "auth/credential-already-in-use"){
        setMsg("Gmail is already in used")
      }
      setLoading(false)
    })

    const _default = helper.getDefaultPhoneCode()
    const API = "895203065c2f4afda7351fc1dd649201";
   
    fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${API}`).then(async (data) => {

      const _json = await data.json()

      const countryName = _json.country_name

      try{
        logEvent(analytics, AnalyticsNames.ipaddress, {
          country_type: countryName,
          content_type: countryName,
          item_id: countryName, 
        })  
      }catch{}
      const countryCode = _json.country_code2
      if(countryCode){
        setCountryCode(countryCode)
        localStorage.setItem(country, countryCode)
        setCurrentUser({country: countryCode})
      }else{
        setCountryCode(_default)
      }
      
    }).catch(() => {
      setCountryCode(_default)
    })
// eslint-disable-next-line
  }, [])

  if(isLoading) return <LoadingScreen/>
  else return (
    
    <div>
      <div>
      
      <div className = "center-main">
        <Card className="card">

        {verifying && <LinearProgress color="secondary" />}

        <div className="center">

        <div>
            <div className="center">
              <img  height = "156px" width = "156px" src = {AppLogo} alt="logo"/>
              <Typography variant="caption" color = "error" >{msg}</Typography>
            </div>
          
          </div>

        </div>
      
        <div id="recaptcha-container" className = "center" ></div>
          <CardContent>

            <div >
          {!countryCode && <LinearProgress color="secondary"/>} 
          <PhoneInput
            onKeyDown ={(e) =>{
              if(e.keyCode === ENTER_KEY_CODE && ageLimit) sendPressed();
            }}

            disabled={countryCode ? false : true}
            inputStyle={{color: "black", opacity: countryCode ? "1.0" : "0.32"}}
            country={countryCode?.toLowerCase()}
            value={phone}
            onChange={phone => {
              setPhone(phone)
            }}>
             
            </PhoneInput>

            </div> 

            <br/>

            <div className="div-code">

                <input 
                id="input-code" 
                required type = "tel" className = "ion-input-verify" placeholder = "code" 
                disabled = {result === undefined}
                onKeyUp ={(e) =>{

                  if(e.keyCode === ENTER_KEY_CODE){
                      verifyPressed();
                  }
                }}
                onChange = {event => 

                  {
                    setVerificationCode((event.target as HTMLInputElement).value)
                  }
                }></input>

                <div hidden={!send}>
                  <CircularProgress  className="loading-circle" color="secondary"/>
                </div>
          
              
            </div>   
            
            <hr/>
            

            <FormControlLabel control={<Checkbox checked={ageLimit}  color='secondary' onChange={onChangeHandle} />} label={<DialogContentText fontSize={14}>I am 18 and above</DialogContentText>} />

            <div className="btn-container">
              <Button variant="contained"  color="secondary"  disabled = {result !== undefined || !ageLimit || google} onClick= {sendPressed}>Send</Button>
              <Button variant="contained"  color="secondary" disabled = {result === undefined || verifying || google} onClick={verifyPressed}>Verify</Button>
              <Button  variant="contained" color="secondary"  disabled = {result === undefined || google} onClick={() => {window.location.reload()}}>RETRY</Button>
            </div>

            <br/>
            <Divider />
            <br/>

              <Button
                fullWidth 
                disabled={google || verifying}
                startIcon={
                  <img
                  width={21}
                  height={21} 
                  src="https://images.rentbabe.com/assets/logo/google.svg"
                  alt=""
                  />
                } 
                variant="contained" 
                color="primary"
                onClick={googleSignInClick}
              >
              Sign {openAdminPage ? "up" : "in"} with Google

              </Button>

              {/* <Button color="primary" variant="contained" fullWidth 
              disabled={google || verifying}  
              startIcon={
                <img
                width={21}
                height={21} 
                  src="https://images.rentbabe.com/assets/logo/google.svg"
                  alt=""
                />
              } 
              endIcon={
                <>
                  {google && <CircularProgress size={12} color="secondary"/>}
                </>
              }
                onClick={googleSignInClick}></Button> */}
            
          </CardContent>
        </Card>

      </div>
      
      <br/>

      <div className = "center-main">
        <p className = "policy-label" >By signing up, you agree to our <a href = "/Terms" target = "_blank" >Terms</a> and that you have read our <a href = "/Privacy" target = "_blank">Data Use Policy</a></p>
        <p className = "policy-label" >Promoting illegal commercial activities is prohibited.</p>
      </div>
      
      </div>
      
    </div> 

  );
}


export default LoginPage;