import { FC } from 'react';
import history from '../../utility/history'

import { useTranslation } from 'react-i18next';

import '../../scss/components/Buttons/button.scss'
import CenterFlexBox from '../Box/CenterFlexBox';

const LoginButton : FC= () => {

    const { t } = useTranslation()

    const onClick = () => {
        history.push("/Login", {openAdminPage: false})
    }

    return <CenterFlexBox 
            className = "login-button"  
            onClick={onClick}
        >
        {t("login.button")}
    </CenterFlexBox>
 
}

export default LoginButton