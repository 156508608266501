import { FC } from 'react';
import { Box } from '@mui/system';

interface props  {
    gap?: number
}

const FlexGap : FC<props> = ({gap = 4}) => {

    return <Box  margin={`0 ${gap}px`}/>

}

export default FlexGap