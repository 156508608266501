import { Box, BoxProps } from '@mui/system';
import { FC } from 'react';
import shallow from 'zustand/shallow';
import { useUser } from '../../store';
import { Helper } from '../../utility/Helper';
import history from '../../utility/history';
import '../../scss/components/Buttons/button.scss'
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../store/firebase';
import { AnalyticsNames } from '../../keys/analyticNames';
import { useTranslation } from 'react-i18next';

const BabeButton : FC<BoxProps>= ({...props}) => {

    const {t} = useTranslation()

    const [ uid, isAdmin ] = useUser((state) => [
        state.currentUser?.uid, state.currentUser?.isAdmin
    ], shallow )

    const onClick = () => {

        try{
            logEvent(analytics, AnalyticsNames.buttons, {
              content_type: "babe button",
              item_id: "Babe button", 
            })  
        }catch{}

        if(!uid){
            history.push("/login", {openBabeSignup: true})
            return
        }

        const helper = new Helper()

        const shouldRefresh = helper.getQueryStringValue("babe") !== "true"

        // const _state = sessionStorage.getItem(state).toLowerCase()
        // const _club = sessionStorage.getItem(club).toLowerCase()

        //const url = `/page/Admin?uid=${uid}&babe=true`
        var url = "/page/admission"

        // if(clubName && clubState){
        //     url += `${club}=${_club}&${state}=${_state}`
        // }

        if(shouldRefresh){
            window.location.href = url
        }else{
            history.push(url)
        }
    }

    return <Box 
            {...props}
            className = "babe-button"
            bgcolor={isAdmin === false ? "red" : "black"}  
            onClick={onClick}
        >
        {isAdmin === false ? "In review" : t("babe.button")}
    </Box>
 
}

export default BabeButton