import { initializeApp } from "firebase/app"
import { getStorage } from "firebase/storage"
import {getFirestore}  from "firebase/firestore"
import { getAuth }  from "firebase/auth"
import { getAnalytics } from 'firebase/analytics'
import { getFunctions } from 'firebase/functions' // connectFunctionsEmulator

export const config = {
    apiKey: "AIzaSyDs_1FUVYKalwFWnxlAMGd953dqkSHXj6U",
    //authDomain: "rent-a-date-81735.firebaseapp.com",
    authDomain: "rentbabe.com",
    databaseURL: "https://rent-a-date-81735.firebaseio.com",
    projectId: "rent-a-date-81735",
    storageBucket: "images.rentbabe.com",
    messagingSenderId: "464795139098",
    appId: "1:464795139098:web:87ce25ea4154dad390e5a3",
    measurementId: "G-K2SHMBYTJ3"
}

export const firebaseApp = initializeApp(config)
export const storage = getStorage(firebaseApp)
export const db = getFirestore(firebaseApp)
export const auth = getAuth(firebaseApp)
export const analytics = getAnalytics(firebaseApp)
export const functions = getFunctions(firebaseApp)
//connectFunctionsEmulator(functions, "localhost", 5001);