export const MALAYSIA = ["Kuala Lumpur","Malaysia"]
export const SINGAPORE = ["Singapore"]
export const COLOMBIA = ["Colombia"]
export const PHILIPPINES = ["Metro Manila","Philippines"]
export const SOUTH_KOREA = ["Seoul", "South Korea"]
export const INDONESIA = ['Jakarta', 'Indonesia']

export const ipgeolocationAPIKEYS = [
    "c31ac4c7e9d8446eb93369fab59a8a57",
    "895203065c2f4afda7351fc1dd649201",
    "6b05fa66d4984b7e8aef36c2edadbbf5",
    "9a7a67c6878b4eb8bd97773bbd0323c7",
    "e06b82483156424b8cd28eabb7f72f6a",
    "ce2d4471f2b4437f8e437c1c43ea329e",
    "efd64941a278447ab8cd64bd76bad5d5",
    "61a690222fab49fc937792f98f58ebee",
    "0c7587ea3c2d469aa92928be6babfe20",
    "cec159e477c2428e90758f691311504a",
    "01b3a7db94c54a438706df20c718cc67",
    "0c1d3d2012fd468bb7646614b48498fd",
    "770715a020b54f8ea1abb415bb114ed9",
    "3d1c524ada0f4c2081ea89ad21108fa4",
    "ddcf3740f08949c09eec5ee4ad22c7f1",
    "880deafddad14ab4984ecab389bd7a5b",
    "d38eb1aeeae2455a96c2c5a2c7f083f9",
    "6371bebf03fa4225add20764be13f842",
    "ca6e256bb0a34749a82f933770c5e367",
    "f3f5c3f399504a6bb8836c9aa9028857",
    "8e4ce952b9cc47c0b8deabbd98359557",
    "8d91292e5d1e4c78a7e5e0d25238083b",
    "b9f6887118ec458c9c6db44c2e199cb0",
    "c68d0acaeef841ef8083fa63fae6705b",
    "83173daf3b094244b2c3a4baba46ed6e",
    "6e08a65201b445278ea332069670062a",
    "e85550819a70402abe5f75702611576d",
    "e08f527c57674448baaa7f2d9409b6f3",
    "49d643033e7743b5a813eb0e21e58948",
    "c0bec0f088974ce0a650caad4ffed938",
    "9617cd1fc759482dbd2fe7f1cfa14e68",
    "5005217cca8841f6a9d4c0e3cc06e9bc",
    "e489aab1bf5a476b9d05ba7681dd3988",
    "6b91d05571c24ed79829cc9dbe1a77c0",
    "558526aa6a2544769888a8d5981d52ce",
    "f348a551b7404f8282627335a4d0b3f4",
    "357ad7aafc7b4e8ab82456fb96af35b5",
    "b5fbb10565814e82986796d296179fe0",
    "4ea1630dc1af4ca68398aa8241a6f86d",
    "10c79268835b48158eb59188660ed636",
    "489e9b8d52a3443a80e715b48672f082",
    "d4d949aa2a8b477fb5594ad3d84a5364",
    "5dff59cdd35b4f3eaf00f42f33b0abf0",
    "90d456eca5bc44118aef039436c7bc3b",
    "5970053b839947ab9b51f5939d5dbb8b",
    "e1b740abdac94299871cf3b52f556cb1",
    "604ce2be5891484b9e1e9bb3bfc8c388",
    "dc7e57ab0795496d8e10a9bd9a5c559c",
    "9c7e3de9a2314889a016974b5ffa661c",
    "df8c58715e2f4e07aa359aa8f1298272",
    "2e3c144d7ce04abd81010afecf93f40a",
    "a28771a087434d3188ff575a39ac2c21",
    "59c8d39d35144d50829f264ff92fd355",
    "cd5964d48ae6487bb8caf55a72e6d752",
    "224e1317db1c4ea2975e4c42030173a6",
    "b01e4bf3d06a406ba0f0d1ddb60e3f8b",
    "7f887d7793a447019d5991ab85ec6072",
    "f305213326b24e6c9ff61304039d5c3a",
    "92b2c2b92d6e4a09b9ae4cec884623f9",
    "3785e17215b14240b2ebdbb77e79c8cb",
    "6c8817132b9a465a83e1d5ae5eb3a23b",
    "e9a2f1852c4a48208789fb96456a0c41",
    "72879740e6794527b9671cde5cc7698d",
    "7ec573f393e5452c92ffa666c6d2b9b8", 
    "101962baadd64733b2d7bd9233ec39a4",
    "36e66b8942f949e7ae762ac87c2917a7",
    "f377bb2b3aa9490a94b079c4be971ba4",
    "85438a5a1174437383133ae30598c10d",
    "54eba5bd432f4439b8d94fe944f5c5c9",
    "fcc0a909d466463d881205337ab54095",
    "7cf4cad8a6954fb0b3639eaffb4cfec9",
    "8a52bf1f624b4b6eae52cf161f4ec761",
    "2f9ee15fc14447eda403990576df8ee9",
    "a3293f0234014562b6e8c7a6953fee2b",
    "9a194877771544149fafdf7ae8077a14",
    "47b5c15e33a9443082e627dea998c005",
    "5661467ab6dd4f699e8e96b8c9aeda92",
    "9521ef70713f449b907f6ce04e6443b6",
    "79a92a0df00b4158aa5918e9f9ccc7d8",
    "b810fb3755a64fa5aa29145aef3dd4ed",
    "349981624053476d9ec7703f02baf267",
    "85649e7de1d047cfb78a0b8af4694b41",
    "083832748b95443c9c6685400fb3f4a3",
    "d3f32c0e5d8a4f27ad2c7f915325b406",
    "1918318eb12a4939ae1502c985339867",
    "7db7428a2e634020a861aef5330c3ef2",
    "dce59b89e22348eca27ecdf4bda71056",
"e545edd787d34d109faa5d178ec65e18",
"d41a357a08874b6aaef49bb901a095cc",
"0d1fc6fe2631466f9fbbb52329076731",
"280d4123641f4e54b45c26e06fea2844",
"f3ffa14c7a434fc3919e6f7ffcdb25de",
"00c88ab3bcd64d12b5e3d498fea45b0f",
"1d2cc809d5ef46c998061cb5786651c0",
"03a8319c72b441a593307cf26ccf64fd",
"58d65af8f8d5484f98e520b7ff76a1d6",
"855e9859c0734b1f849e73a87c59cf9b",
"d3db7fc52a06424c914fb0991031ba37",
"f1bc2ee71dd1400397fa06ed93eceaf4",
"ac40dc18029042209ead9f9d874753d7",
"9e4cfeb703144fb1ba4543be941315c2",
"cfb14f694d64496faa8419cc9a9b6acf",
"e068e4f11e8f4678b08d8391609cc1fc",
"6e9a10c70e6940f1af052ceb3ca478c0",
"43750f83334f4dfebcd9d70f035e553d",
"d5173c38b3d447f5b32c973fdcb07b51",
"1cf00344b12c422c8c159d5942051352",
"bbd190b3d1314d35907ac9be3f57810c",
"766f124f643d4e2abc901b07ca810852",
"5deafbd63d27409b9e424483fe78a863",
"333ec32f7ca041b99f1b5506542a8ca0",
"e83b69b9064c45f48fc1472b17ff6d58",
"e3ad34b868a1454e937655fd895c1cb5",
"18547a77baad4b03ba13813366a1a2fb",
"4616550f7370409ba5d2c58d8c4dc4b8",
"e11232b71d9543ca908f9c2e3bfbc169",
"9840f131c213483b8135718563f93add",
"1a816511203a456689b9e504b2abc85a",
"c7ceb98581134b9da675a891a29fffdf",
"7f4313445604451e86372841005460f7",
"973846de076b4c91bc624007e9377af8",
"ff8a0c21aa044cd19cc4ef0f7ef2f520",
"6f7062fe2fb047f69370b703fae2779f",
"17503a184567411b8a8e2e075a89ef72",
"e86f860a65154dc7b6970b9e2b8c5b0b",
"c5ab60d4e6f640cb962863fbd50008f6",
"11530904c7ef4a5b985098a454c06c5e",
"a5c149c5a31c4b20a595fd67e2116c5b",
"d17f99ef7d1f449db81450c8d2245214",
"74f1b0df55c04447bd20a41fabad7c7a",
"c84dd61a32394d9aba204fc3028c51e0",
"6472b15e31b24f598599b3078379a8dc",
"c17e3b3bc81a43bcb4549a23fc8526f5",
"cf3b1c44e0c24c46a99f08dd2b1f50e3",
"71e8e8d86bcd4d8882e4a9b98e7cc3e1",
"d68b72cf266c438d8722538a49fc3f1f",
"8aa35af570fd434285244b14f949e2ab",
"3ee57b76b4f1410ea49fe673c3d1b813",
"f2c9f1fe504f41fd85d78a2a06b2633d",
"d4205a8d7b6d4e728caa05b2864402c5",
"17dac771f6e24a42b4d52ae077d75721",
"97532ad1f7814623870c9c4540094e45",
"8d1ddf93ea584b7b812d374b99074716",
"b0ce046286eb494282537a969763292f",
"4187cc13e2594df9bd9cedbed1a54131",
"5f9ddd1e5ce6449b92315216803f4bb1",
"bd2b9e3d04084527ab0b0a9041e7c035",
"0d43fc020de746a5bfe66d9fe2981783",
"8ca0ddbced5045cfa3c43d8dc0e6cf6a",
"2af62e19be0d4588bcbc260179853c5a",
"11394c5f6a0e4ff8bf8b90457eb132cf",
"57b9d28eb4be4d9ea1ec59cd90b23c4e",
"fe17be5f9da84c7bac2d95257550f938",
"87135367aca9400ab12d254d8200ca94",
"1821fe347f9d4ce489692ce35292e31e",
"19d3bb5c77ae4b379b514a7c03a5acce",
"a502a7354ae648ca9af99d7325d04564",
"73c82523b62a4fa0a9538acfe1e0a36f",
"997e11a538014cf29d15e3e0bdedf70e",
"c64159b609af4955ae23b0c529aa3ad9",
"24c0d61d7cff496cbb05aac3ab66e550",
"89dfcfdf3f0c4451898fc1be3bd59486",
"88c0fc9053ca4f12bd02d6d3cd3e205d",
"8e72b8074b8f4e20b1ec14cb4ce7996e",
"2d67c9911b5843b69a04c55b5624bfc4",
"0e4c2ddcc3b8469f9d6c0499fd69905a",
"50a4be9c731949ca93327a97282d5dac",
"4ea452d5e70d4431adc26d44f5632a1e",
"e8113eec1211474fa987b296d54f27a6",
"9edc9a45f4794406a2be3017c3f7c866",
"ac6080883b6d417db3cf8d44c1996686",
"93670971cc8242229fec1257d06c521f",
"dd2b304964434f57a07f840a3d655a86",
"ca255a0276fc41048842f15f3f5ecc92",
"c6579dbda97b4570a29d3b475a6ff8a1",
"27885337f67d41dc97a5173425c0160f",
"7a89f9fed9a248db9d2fbe6082239400",
"72d64f9166b541b0aa9cdf255acaf650",
"1c7710a54aad46a997a9fececdb41982"
]