import { FC } from 'react';
import history from '../../utility/history'
import '../../scss/components/Buttons/button.scss'
import { useTranslation } from 'react-i18next';

const SignUpButton : FC = () => {

    const { t } = useTranslation();

    const onClick = () => {
        history.push("/Login", {openAdminPage: true})
    }

    return <div className = "signup-button"  onClick={onClick}>
        {t("signup.button")}
    </div> 
 
}

export default SignUpButton